<template>
    <parametro-form
        acao="ATUALIZAR"
        :cancelar="cancelar"
        :erros="erros"
        :parametro="parametro"
        @salvar="atualizar($event)"
        @atualizar="obterParametro()"
    ></parametro-form>
</template>

<script>
import ParametroForm from './ParametroForm';
import ParametrosService from './services';

export default {
    components: {
        ParametroForm,
    },

    data() {
        return {
            erros: [],
            parametro: null,
        };
    },

    computed: {
    },

    methods: {
        cancelar() {
            this.$router.push({
                name: `Parametros_Detalhar`,
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterParametro() {
            this.$store.dispatch('addRequest');
            ParametrosService.obterPorId(this.$route.params.id).then(response => {
                if (response && response.success) {
                    this.parametro = response.data;
                } else {
                    this.parametro = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(parametroAtualizado) {
            this.$store.dispatch('addRequest');
                let parametroAtualizadoDto = {
                    parametroId: this.$route.params.id,
                    codigo: parametroAtualizado.codigo,
                    descricao: parametroAtualizado.descricao,
                    valor: parametroAtualizado.valor,
                    detalhe: parametroAtualizado.detalhe,
                };
            ParametrosService.atualizar(parametroAtualizadoDto).then(response => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Parâmetros',
                        detail: 'Parâmetro atualizado com sucesso',
                        life: 3000,
                    });
                    this.$store.dispatch('setAtualizar', true);
                    this.parametro = response.data;
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterParametro();
    },
};
</script>
